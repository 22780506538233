define("shared/components/cluster-driver/driver-oracleoke/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-oracleoke/template", "shared/utils/parse-version"], function (_exports, _clusterDriver, _template, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var regionMap = {
    'Mumbai': 'ap-mumbai-1',
    'Seoul': 'ap-seoul-1',
    'Tokyo': 'ap-tokyo-1',
    'Toronto': 'ca-toronto-1',
    'Frankfurt': 'eu-frankfurt-1',
    'Zurich': 'eu-zurich-1',
    'Sao Paolo': 'sa-saopaulo-1',
    'London': 'uk-london-1',
    'Ashburn': 'us-ashburn-1',
    'Phoenix': 'us-phoenix-1'
  };
  var k8sVersionMap = {
    'v1.15.7': 'v1.15.7',
    // default
    'v1.14.8': 'v1.14.8'
  };
  var vcnIdMap = {
    quick: 'Quick Create'
  };
  var subnetAccessMap = {
    public: 'Public',
    private: 'Private'
  };
  var nodeShapeMap = {
    'VM.Standard1.1': 'VM.Standard1.1',
    'VM.Standard1.2': 'VM.Standard1.2',
    'VM.Standard1.4': 'VM.Standard1.4',
    'VM.Standard1.8': 'VM.Standard1.8',
    'VM.Standard1.16': 'VM.Standard1.16',
    'VM.Standard2.1': 'VM.Standard2.1',
    'VM.Standard2.2': 'VM.Standard2.2',
    'VM.Standard2.4': 'VM.Standard2.4',
    'VM.Standard2.8': 'VM.Standard2.8',
    'VM.Standard2.16': 'VM.Standard2.16',
    'VM.Standard2.24': 'VM.Standard2.24',
    'BM.Standard.E2.64': 'BM.Standard.E2.64',
    'BM.Standard2.52': 'BM.Standard2.52',
    'BM.Standard.B1.44': 'BM.Standard.B1.44',
    'BM.DenseIO2.52': 'BM.DenseIO2.52',
    'BM.HPC2.36': 'BM.HPC2.36',
    'VM.Standard.E2.1.Micro': 'VM.Standard.E2.1.Micro',
    'VM.Standard.E2.2': 'VM.Standard.E2.2',
    'VM.GPU2.1': 'VM.GPU2.1',
    'VM.GPU2.2': 'VM.GPU2.2',
    'VM.GPU3.1': 'VM.GPU3.1',
    'VM.GPU3.2': 'VM.GPU3.2',
    'VM.GPU3.4': 'VM.GPU3.4',
    'VM.GPU3.8': 'VM.GPU3.8'
  };
  var imageMap = {
    'Oracle-Linux-7.6': 'Oracle-Linux-7.6',
    'Oracle-Linux-7.5': 'Oracle-Linux-7.5',
    'Oracle-Linux-7.4': 'Oracle-Linux-7.4'
  };

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'okeEngineConfig',
    instanceConfig: '',
    step: 1,
    lanChanged: null,
    refresh: false,
    vcnCreationMode: '',
    vpcs: null,
    subnets: null,
    eipIds: null,
    nodeFlavors: null,
    keypairs: null,
    availableZones: null,
    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),
    init: function init() {
      this._super.apply(this, arguments);

      var config = Ember.get(this, 'cluster.okeEngineConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          type: 'okeEngineConfig',
          secretKey: '',
          clusterName: '',
          vcnCidr: '10.0.0.0/16',
          kubernetesVersion: 'v1.15.7',
          region: 'us-phoenix-1',
          vcn: '',
          securityListId: '',
          subnetAccess: 'public',
          cpu: 0,
          memory: 0,
          quantityPerSubnet: 1,
          quantityOfNodeSubnets: 1
        });
        Ember.set(this, 'cluster.okeEngineConfig', config);
      } // init cpu and memory


      var _EmberGet = Ember.get(this, 'config'),
          cpu = _EmberGet.cpu,
          memory = _EmberGet.memory;

      if (cpu && memory) {
        Ember.set(this, 'instanceConfig', "".concat(Ember.get(this, 'config.cpu'), "/").concat(Ember.get(this, 'config.memory')));
      }
    },
    actions: {
      // TODO implement authenticateOCI
      authenticateOCI: function authenticateOCI(cb) {
        Ember.setProperties(this, {
          'errors': null,
          'config.userOcid': (Ember.get(this, 'config.userOcid') || '').trim(),
          'config.secretKey': (Ember.get(this, 'config.secretKey') || '').trim(),
          'config.privateKeyPassphrase': (Ember.get(this, 'config.privateKeyPassphrase') || '').trim(),
          'config.region': Ember.get(this, 'config.region')
        });
        Ember.set(this, 'step', 2);
        cb(true);
      },
      // TODO re-implement loadNodeConfig
      loadNodeConfig: function loadNodeConfig(cb) {
        Ember.set(this, 'step', 3);
        cb(true);
      },
      // TODO implement loadInstanceConfig
      loadInstanceConfig: function loadInstanceConfig(cb) {
        Ember.set(this, 'errors', null);
        Ember.set(this, 'step', 4);
        cb(true);
      },
      upgradeCluster: function upgradeCluster(cb) {
        Ember.setProperties(this, {
          'errors': null
        });
        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');
        var quantityPerSubnet = Ember.get(this, 'config.quantityPerSubnet');
        var kubernetesVersion = Ember.get(this, 'config.kubernetesVersion');

        if (!quantityPerSubnet) {
          errors.push(intl.t('clusterNew.oke.quantityPerSubnet.required'));
        } else {
          var maxNodeCount = Ember.get(this, 'config.maxNodeCount');

          if (!/^\d+$/.test(quantityPerSubnet) || parseInt(quantityPerSubnet, 10) < 0 || parseInt(quantityPerSubnet, 10) > maxNodeCount) {
            errors.push(intl.t('clusterNew.oke.quantityPerSubnet.error', {
              max: maxNodeCount
            }));
          }
        }

        if (!kubernetesVersion) {
          errors.push(intl.t('clusterNew.oke.version.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        this.send('driverSave', cb);
      },
      save: function save(cb) {
        Ember.setProperties(this, {
          'errors': null,
          'otherErrors': null,
          'clusterErrors': null
        });
        var errors = Ember.get(this, 'errors') || [];

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb(false);
          return;
        }

        if (!this.validate()) {
          cb(false);
          return;
        }

        if (Ember.get(this, 'config.nodeImage') === '') {
          Ember.set(this, 'config.nodeImage', imageMap['Oracle-Linux-7.6']);
        }

        if (Ember.get(this, 'config.vcnCompartmentId') === '') {
          Ember.set(this, 'config.vcnCompartmentId', Ember.get(this, 'config.compartmentId'));
        }

        if (Ember.get(this, 'config.subnetAccess') === 'public') {
          Ember.set(this, 'config.enablePrivateNodes', false);
        } else {
          Ember.set(this, 'config.enablePrivateNodes', true);
        }

        this.send('driverSave', cb);
      },
      cancel: function cancel() {
        Ember.get(this, 'router').transitionTo('global-admin.clusters.index');
      },
      cpuAndMemoryChanged: function cpuAndMemoryChanged(item) {
        Ember.setProperties(this, {
          'config.cpu': item.raw.cpuCount,
          'config.memory': item.raw.memoryCapacityInGB
        });
      }
    },
    maxNodeCount: Ember.computed('clusterQuota.slave', function () {
      return 256;
    }),
    regionChoices: Object.entries(regionMap).map(function (e) {
      return {
        label: e[0],
        value: e[1]
      };
    }),
    selectedRegion: Ember.computed('config.region', function () {
      var region = Ember.get(this, 'config.region');
      return region;
    }),
    vcnChoices: Object.entries(vcnIdMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    selectedVCN: Ember.computed('config.vcnId', function () {
      var vcnId = Ember.get(this, 'config.vcnId');
      return vcnId && vcnIdMap[vcnId];
    }),
    subnetAccessChoices: Object.entries(subnetAccessMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    selectedSubnetAccess: Ember.computed('config.subnetAccess', function () {
      var subnetAccess = Ember.get(this, 'config.subnetAccess');
      return subnetAccess && subnetAccessMap[subnetAccess];
    }),
    nodeShapeChoices: Object.entries(nodeShapeMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    selectednodeShape: Ember.computed('config.nodeShape', function () {
      var nodeShape = Ember.get(this, 'config.nodeShape');
      return nodeShape && nodeShapeMap[nodeShape];
    }),
    imageChoices: Object.entries(imageMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    selectedImage: Ember.computed('config.nodeImage', function () {
      var nodeImage = Ember.get(this, 'config.nodeImage');
      return nodeImage && imageMap[nodeImage];
    }),
    k8sVersionChoices: Object.entries(k8sVersionMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    k8sUpgradeVersionChoices: Ember.computed('config.kubernetesVersion', function () {
      var supportedVersions = Object.assign({}, k8sVersionMap);
      var currentVersion = Ember.get(this, 'config.kubernetesVersion');
      Object.keys(supportedVersions).filter(function (key) {
        return (0, _parseVersion.compare)(key, currentVersion) < 0;
      }).forEach(function (key) {
        return delete supportedVersions[key];
      });
      return Object.entries(supportedVersions).map(function (e) {
        return {
          label: e[1],
          value: e[0]
        };
      });
    }),
    selectedk8sVersion: Ember.computed('config.kubernetesVersion', function () {
      var k8sVersion = Ember.get(this, 'config.kubernetesVersion');
      return k8sVersion && k8sVersionMap[k8sVersion];
    }),
    canAuthenticate: Ember.computed('config.tenancyId', 'config.compartmentId', 'config.userOcid', 'config.fingerprint', 'config.privateKeyContents', function () {
      return Ember.get(this, 'config.tenancyId') && Ember.get(this, 'config.compartmentId') && Ember.get(this, 'config.userOcid') && Ember.get(this, 'config.fingerprint') && Ember.get(this, 'config.privateKeyContents') ? false : true;
    }),
    canSaveVCN: Ember.computed('vcnCreationMode', 'config.vcnName', 'config.loadBalancerSubnetName1', 'config.loadBalancerSubnetName2', 'config.subnetAccess', 'config.vcnCidr', function () {
      var mode = Ember.get(this, 'vcnCreationMode');

      if (mode === 'Quick') {
        return false;
      } else if (mode === 'Existing') {
        // Driver will use the same compartment as the cluster if not set.
        return Ember.get(this, 'config.vcnName') && Ember.get(this, 'config.loadBalancerSubnetName1') ? false : true;
      } else if (mode === 'Custom') {
        return Ember.get(this, 'config.subnetAccess') && Ember.get(this, 'config.vcnCidr') ? false : true;
      }

      return true;
    }),
    canCreateCluster: Ember.computed('config.nodeShape', function () {
      return Ember.get(this, 'config.nodeShape') ? false : true;
    }),
    // Add custom validation beyond what can be done from the config API schema
    validate: function validate() {
      // Get generic API validation errors
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'cluster.name')) {
        errors.push('Name is required');
      }

      var tenancyId = Ember.get(this, 'config.tenancyId');

      if (!tenancyId.startsWith('ocid1.tenancy')) {
        errors.push('A valid tenancy OCID is required');
      }

      var compartmentId = Ember.get(this, 'config.compartmentId');

      if (!compartmentId.startsWith('ocid1.compartment')) {
        errors.push('A valid compartment OCID is required');
      }

      var userOcid = Ember.get(this, 'config.userOcid');

      if (!userOcid.startsWith('ocid1.user')) {
        errors.push('A valid user OCID is required');
      } // TODO Add more specific errors
      // Set the array of errors for display,
      // and return true if saving should continue.


      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);
        return false;
      } else {
        Ember.set(this, 'errors', null);
        return true;
      }
    },
    willSave: function willSave() {
      if (Ember.get(this, 'mode') === 'new') {
        if (Ember.get(this, 'config.nodeImage') === '') {
          Ember.set(this, 'config.nodeImage', imageMap['Oracle-Linux-7.6']);
        }

        if (Ember.get(this, 'config.vcnCompartmentId') === '') {
          Ember.set(this, 'config.vcnCompartmentId', Ember.get(this, 'config.compartmentId'));
        }

        if (Ember.get(this, 'config.subnetAccess') === 'public') {
          Ember.set(this, 'config.enablePrivateNodes', false);
        } else {
          Ember.set(this, 'config.enablePrivateNodes', true);
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});