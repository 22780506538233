define("shared/components/drain-node/component", ["exports", "shared/components/drain-node/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_TIMEOUT = 10800;
  var HONOR_GRACE_PERIOD = -1;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    editable: true,
    selection: null,
    proxyGracePeriod: null,
    usePodGracePeriod: false,
    proxyTimeout: null,
    unlimitedTimeout: false,
    view: Ember.computed.not('editable'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'selection.deleteLocalData', typeof this.selection.deleteLocalData === 'undefined' ? 'false' : this.selection.deleteLocalData.toString());
      Ember.set(this, 'usePodGracePeriod', this.selection.gracePeriod === HONOR_GRACE_PERIOD);
      Ember.set(this, 'proxyGracePeriod', this.selection.gracePeriod === HONOR_GRACE_PERIOD ? 30 : this.selection.gracePeriod);
      Ember.set(this, 'unlimitedTimeout', this.selection.timeout === MAX_TIMEOUT);
      Ember.set(this, 'proxyTimeout', this.selection.timeout === MAX_TIMEOUT ? 60 : this.selection.timeout);
    },
    updateGracePeriod: Ember.observer('usePodGracePeriod', 'proxyGracePeriod', function () {
      var newGracePeriod = Ember.get(this, 'usePodGracePeriod') ? HONOR_GRACE_PERIOD : Ember.get(this, 'proxyGracePeriod');
      Ember.set(this, 'selection.gracePeriod', newGracePeriod);
    }),
    updateTimeout: Ember.observer('unlimitedTimeout', 'proxyTimeout', function () {
      var newTimeout = Ember.get(this, 'unlimitedTimeout') ? MAX_TIMEOUT : Ember.get(this, 'proxyTimeout');
      Ember.set(this, 'selection.timeout', newTimeout);
    }),
    mode: Ember.computed('selection.deleteLocalData', function () {
      return this.selection.deleteLocalData === 'true' ? this.intl.t('drainNode.aggressive.label') : this.intl.t('drainNode.safe.label');
    }),
    gracePeriodForPods: Ember.computed('selection.usePodGracePeriod', 'selection.gracePeriod', function () {
      return this.usePodGracePeriod ? this.intl.t('drainNode.gracePeriod.default') : this.intl.t('drainNode.gracePeriod.view.custom', {
        seconds: this.selection.gracePeriod
      });
    }),
    timeout: Ember.computed('selection.unlimitedTimeout', 'selection.timeout', function () {
      return this.unlimitedTimeout ? this.intl.t('drainNode.timeout.default') : this.intl.t('drainNode.timeout.view.custom', {
        seconds: this.selection.timeout
      });
    })
  });

  _exports.default = _default;
});