define("nodes/components/driver-amazonec2/component", ["exports", "jquery", "shared/mixins/node-driver", "nodes/components/driver-amazonec2/template", "shared/utils/amazon", "shared/utils/util"], function (_exports, _jquery, _nodeDriver, _template, _amazon, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RANCHER_GROUP = 'rancher-nodes';

  var _default = Ember.Component.extend(_nodeDriver.default, {
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    driverName: 'amazonec2',
    clients: null,
    allSubnets: null,
    allSecurityGroups: null,
    selectedSecurityGroup: null,
    defaultSecurityGroup: null,
    defaultSecurityGroupName: RANCHER_GROUP,
    whichSecurityGroup: 'default',
    instanceTypes: _amazon.INSTANCE_TYPES,
    regionChoices: _amazon.REGIONS,
    step: 1,
    tags: null,
    editing: false,
    config: Ember.computed.alias('model.amazonec2Config'),
    isCustomSecurityGroup: Ember.computed.equal('whichSecurityGroup', 'custom'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        clients: Ember.Object.create(),
        allSubnets: []
      });
      var cur = Ember.get(this, 'config.securityGroup');

      if (cur === '') {
        // TODO 2.0 should this be null 403 Vince/Wes/Daishan
        Ember.setProperties(this, {
          whichSecurityGroup: 'default',
          selectedSecurityGroup: null
        });
      } else {
        Ember.setProperties(this, {
          whichSecurityGroup: 'custom',
          selectedSecurityGroup: cur
        });
      }

      var tagsString = Ember.get(this, 'config.tags');

      if (tagsString) {
        var array = tagsString.split(',');
        var tags = {};

        for (var i = 0; i < array.length - 1; i = i + 2) {
          tags[array[i]] = array[i + 1];
        }

        Ember.set(this, 'tags', tags);
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.setProperties(this, {
        step: 1,
        machineId: null,
        clients: null,
        allSubnets: null,
        allSecurityGroups: null,
        whichSecurityGroup: 'default'
      });
    },
    actions: {
      finishAndSelectCloudCredential: function finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'primaryResource.cloudCredentialId', Ember.get(cred, 'id'));
          this.send('awsLogin');
        }
      },
      awsLogin: function awsLogin(cb) {
        var _this = this;

        var self = this;
        Ember.set(this, 'errors', null);
        var subnets = [];
        var rName = Ember.get(this, 'config.region'); // have to have something in there before we describe the request even though we are going to replace with the actual cred id

        var ec2 = new AWS.EC2({
          accessKeyId: (0, _util.randomStr)(),
          secretAccessKey: (0, _util.randomStr)(),
          region: rName,
          httpOptions: {
            cloudCredentialId: Ember.get(this, 'model.cloudCredentialId')
          }
        });
        var vpcNames = {};
        var vpcTags = {};
        ec2.describeVpcs({}, function (err, vpcs) {
          if (err) {
            var errors = Ember.get(self, 'errors') || [];
            errors.pushObject(err);
            Ember.set(_this, 'errors', errors);

            if (cb && typeof cb === 'function') {
              cb();
            }

            return;
          }

          vpcs.Vpcs.forEach(function (vpc) {
            vpcNames[vpc.VpcId] = (0, _amazon.nameFromResource)(vpc, 'VpcId');
            vpcTags[vpc.VpcId] = (0, _amazon.tagsFromResource)(vpc);
          });
          ec2.describeSubnets({}, function (err, data) {
            if (err) {
              var _errors = Ember.get(self, 'errors') || [];

              _errors.pushObject(err);

              Ember.set(_this, 'errors', _errors);

              if (cb && typeof cb === 'function') {
                cb();
              }

              return;
            }

            Ember.set(_this, "clients.".concat(rName), ec2);
            data.Subnets.forEach(function (subnet) {
              if ((subnet.State || '').toLowerCase() !== 'available') {
                return;
              }

              subnets.pushObject(Ember.Object.create({
                subnetName: (0, _amazon.nameFromResource)(subnet, 'SubnetId'),
                subnetId: subnet.SubnetId,
                subnetTags: (0, _amazon.tagsFromResource)(subnet),
                vpcName: vpcNames[subnet.VpcId] || subnet.VpcId,
                vpcId: subnet.VpcId,
                vpcTags: vpcTags[subnet.VpcId] || [],
                zone: subnet.AvailabilityZone,
                region: rName
              }));
            });
            Ember.setProperties(_this, {
              'allSubnets': subnets,
              'step': 2
            });

            if (cb && typeof cb === 'function') {
              cb();
            }
          });
        });
      },
      selectSubnet: function selectSubnet(cb) {
        var _this2 = this;

        Ember.set(this, 'errors', null);

        if (!Ember.get(this, 'selectedZone')) {
          Ember.set(this, 'errors', ['Select an Availability Zone']);

          if (cb && typeof cb === 'function') {
            cb();
          }

          return;
        }

        if (!Ember.get(this, 'selectedSubnet')) {
          Ember.set(this, 'errors', ['Select a VPC or Subnet']);

          if (cb && typeof cb === 'function') {
            cb();
          }

          return;
        }

        var ec2 = Ember.get(this, "clients.".concat(Ember.get(this, 'config.region')));
        var filter = {
          Name: 'vpc-id',
          Values: [Ember.get(this, 'config.vpcId')]
        };
        ec2.describeSecurityGroups({
          Filters: [filter]
        }, function (err, data) {
          if (err) {
            Ember.set(_this2, 'errors', [err]);

            if (cb && typeof cb === 'function') {
              cb();
            }

            return;
          }

          var groups = [];
          data.SecurityGroups.forEach(function (group) {
            var tags = {}; // Skip launch-wizard groups

            if ((group.GroupName || '').match(/^launch-wizard-.*$/)) {
              return;
            }

            (group.Tags || []).forEach(function (tag) {
              tags[tag.Key] = tag.Value;
            });
            var obj = {
              id: group.GroupId,
              name: group.GroupName,
              description: group.Description
            };
            groups.push(obj);
          });
          Ember.setProperties(_this2, {
            allSecurityGroups: groups,
            step: 3
          });
        });
      },
      multiSecurityGroupSelect: function multiSecurityGroupSelect() {
        var options = Array.prototype.slice.call((0, _jquery.default)('.existing-security-groups')[0], 0);
        var selectedOptions = [];
        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'selectedSecurityGroup', selectedOptions);
      },
      selectSecurityGroup: function selectSecurityGroup(cb) {
        Ember.set(this, 'errors', null);

        if (Ember.get(this, 'isCustomSecurityGroup')) {
          Ember.set(this, 'config.securityGroup', Ember.get(this, 'selectedSecurityGroup'));
        } else {
          Ember.set(this, 'config.securityGroup', '');
        }

        Ember.setProperties(this, {
          step: 4
        });

        if (cb && typeof cb === 'function') {
          cb();
        }
      }
    },
    tagsDidChange: Ember.observer('tags', function () {
      var array = [];
      var tags = Ember.get(this, 'tags') || {};
      Object.keys(tags).forEach(function (key) {
        array.push(key);
        array.push(tags[key]);
      });
      Ember.set(this, 'config.tags', array.join(','));
    }),
    stepDidChange: Ember.observer('context.step', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        document.body.scrollTop = document.body.scrollHeight;
      });
    }),
    selectedZone: Ember.computed('config.{region,zone}', {
      get: function get() {
        var config = Ember.get(this, 'config');

        if (Ember.get(config, 'region') && Ember.get(config, 'zone')) {
          return Ember.get(config, 'region') + Ember.get(config, 'zone');
        } else {
          return null;
        }
      },
      set: function set(key, val) {
        var config = Ember.get(this, 'config');
        Ember.setProperties(config, {
          region: val.substr(0, val.length - 1),
          zone: val.substr(val.length - 1)
        });
        var selectedSubnet = Ember.get(this, 'selectedSubnet');

        if (Ember.get(this, 'subnetChoices').filterBy('value', selectedSubnet).length === 0) {
          Ember.setProperties(config, {
            region: val.substr(0, val.length - 1),
            zone: val.substr(val.length - 1),
            vpcId: null,
            subnetId: null
          });
        }

        if (Ember.get(config, 'region') && Ember.get(config, 'zone')) {
          return Ember.get(config, 'region') + Ember.get(config, 'zone');
        } else {
          return null;
        }
      }
    }),
    zoneChoices: Ember.computed('allSubnets.@each.{zone}', function () {
      var choices = (Ember.get(this, 'allSubnets') || []).map(function (subnet) {
        return Ember.get(subnet, 'zone');
      }).sort().uniq();

      if (choices.length) {
        if (Ember.get(this, 'config.zone.length')) {
          Ember.set(this, 'selectedZone', "".concat(Ember.get(this, 'config.region')).concat(Ember.get(this, 'config.zone')));
        } else {
          Ember.set(this, 'selectedZone', choices[0]);
        }
      }

      return choices;
    }),
    subnetChoices: Ember.computed('selectedZone', 'allSubnets.@each.{subnetId,vpcId,zone}', function () {
      var out = [];
      var seenVpcs = [];
      (Ember.get(this, 'allSubnets') || []).filterBy('zone', Ember.get(this, 'selectedZone')).forEach(function (subnet) {
        var vpcName = Ember.get(subnet, 'vpcName');
        var vpcId = Ember.get(subnet, 'vpcId');
        var vpcTags = Ember.get(subnet, 'vpcTags');
        var subnetId = Ember.get(subnet, 'subnetId');
        var subnetName = Ember.get(subnet, 'subnetName');
        var subnetTags = Ember.get(subnet, 'subnetTags');

        if (seenVpcs.indexOf(vpcId) === -1) {
          seenVpcs.pushObject(vpcId);
          out.pushObject({
            sortKey: vpcId,
            label: vpcName,
            value: vpcId,
            isVpc: true,
            tags: vpcTags
          });
        }

        out.pushObject({
          sortKey: "".concat(vpcId, " ").concat(subnetName),
          label: subnetName,
          value: subnetId,
          isVpc: false,
          tags: subnetTags
        });
      });
      return out.sortBy('sortKey');
    }),
    selectedSubnet: Ember.computed('config.{subnetId,vpcId}', {
      set: function set(key, val) {
        var config = Ember.get(this, 'config');

        if (arguments.length > 1) {
          if (val && val.length) {
            if (val.indexOf('vpc-') === 0) {
              Ember.setProperties(config, {
                vpcId: val,
                subnetId: null
              });
            } else {
              var subnet = this.subnetById(val);
              Ember.setProperties(config, {
                vpcId: subnet.vpcId,
                subnetId: subnet.subnetId
              });
            }
          } else {
            Ember.setProperties(config, {
              vpcId: null,
              subnetId: null
            });
          }
        }

        return Ember.get(config, 'subnetId') || Ember.get(config, 'vpcId');
      },
      get: function get() {
        var config = Ember.get(this, 'config');
        return Ember.get(config, 'subnetId') || Ember.get(config, 'vpcId');
      }
    }),
    bootstrap: function bootstrap() {
      var config = Ember.get(this, 'globalStore').createRecord({
        type: 'amazonec2Config',
        region: 'us-west-2',
        instanceType: 't2.medium',
        securityGroup: '',
        zone: 'a',
        rootSize: '16'
      });
      Ember.set(this, 'model.amazonec2Config', config);
    },
    validate: function validate() {
      var errors = [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    subnetById: function subnetById(id) {
      return (Ember.get(this, 'allSubnets') || []).filterBy('subnetId', id)[0];
    }
  });

  _exports.default = _default;
});