define("shared/components/form-network-config/component", ["exports", "shared/components/form-network-config/template", "shared/utils/util", "semver", "shared/utils/parse-version", "shared/utils/constants"], function (_exports, _template, _util, _semver, _parseVersion, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NETWORKCHOICES = [{
    label: 'clusterNew.rke.network.flannel',
    value: 'flannel'
  }, {
    label: 'clusterNew.rke.network.calico',
    value: 'calico'
  }, {
    label: 'clusterNew.rke.network.canal',
    value: 'canal'
  }, {
    label: 'clusterNew.rke.network.weave',
    value: 'weave'
  }];
  var _C$NETWORK_CONFIG_DEF = _constants.default.NETWORK_CONFIG_DEFAULTS,
      FLANNEL = _C$NETWORK_CONFIG_DEF.FLANNEL,
      CANAL = _C$NETWORK_CONFIG_DEF.CANAL,
      WEAVE = _C$NETWORK_CONFIG_DEF.WEAVE,
      VXLAN = _C$NETWORK_CONFIG_DEF.VXLAN,
      DEFAULT_BACKEND_TYPE = _C$NETWORK_CONFIG_DEF.DEFAULT_BACKEND_TYPE,
      BACKEND_PORT = _C$NETWORK_CONFIG_DEF.BACKEND_PORT,
      BACKEND_VNI = _C$NETWORK_CONFIG_DEF.BACKEND_VNI;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    networkContent: NETWORKCHOICES,
    mode: 'new',
    isCustom: false,
    config: null,
    enableNetworkPolicy: null,
    clusterTemplateRevision: null,
    applyClusterTemplate: null,
    windowsSupportOverrideAvailable: false,
    isEdit: Ember.computed.equal('mode', 'edit'),
    init: function init() {
      this._super.apply(this, arguments);

      this.setFlannelLables();
    },
    windowsSupportAvailableDidChange: Ember.observer('windowsSupportAvailable', function () {
      if (!Ember.get(this, 'windowsSupportAvailable')) {
        Ember.set(this, 'cluster.windowsPreferedCluster', false);
      }
    }),
    windowsSupportChange: Ember.observer('cluster.windowsPreferedCluster', function () {
      if (this.mode === 'edit') {
        return;
      }

      var windowsSupport = Ember.get(this, 'cluster.windowsPreferedCluster');
      var config = Ember.get(this, 'config');

      if (!config) {
        return;
      }

      if (windowsSupport) {
        Ember.set(config, 'network.options.flannel_backend_type', VXLAN);
      } else {
        Ember.set(config, 'network.options.flannel_backend_type', DEFAULT_BACKEND_TYPE);
      }
    }),
    flannelBackendDidChange: Ember.observer('cluster.windowsPreferedCluster', 'config.network.plugin', 'config.network.options.flannel_backend_type', function () {
      var config = Ember.get(this, 'config');

      if (config) {
        var plugin = Ember.get(config, 'network.plugin');
        var flannelBackend = Ember.get(config, 'network.options.flannel_backend_type');
        var windowsSupport = Ember.get(this, 'cluster.windowsPreferedCluster');

        if (flannelBackend === VXLAN && plugin === FLANNEL && windowsSupport) {
          Ember.setProperties(config, {
            'network.options.flannel_backend_port': BACKEND_PORT,
            'network.options.flannel_backend_vni': BACKEND_VNI
          });
        } else {
          var options = Ember.get(config, 'network.options');
          delete options['flannel_backend_port'];
          delete options['flannel_backend_vni'];
        }
      }
    }),
    networkPluginDidChange: Ember.observer('config.network.plugin', function () {
      var plugin = Ember.get(this, 'config.network.plugin');

      if (plugin) {
        if (plugin !== CANAL) {
          Ember.set(this, 'enableNetworkPolicy', false);
        }

        if (plugin === WEAVE) {
          Ember.set(this, 'config.network.weaveNetworkProvider', this.globalStore.createRecord({
            type: 'weaveNetworkProvider',
            password: (0, _util.randomStr)(16, 16, 'password')
          }));
        } else if (plugin !== WEAVE && Ember.get(this, 'config.network.weaveNetworkProvider.password')) {
          Ember.set(this, 'config.network.weaveNetworkProvider', null);
        }
      }
    }),
    windowsSupportAvailable: Ember.computed('config.network.plugin', 'config.kubernetesVersion', function () {
      var plugin = Ember.get(this, 'config.network.plugin');
      var kubernetesVersion = Ember.get(this, 'config.kubernetesVersion');
      return plugin === FLANNEL && (0, _semver.gte)((0, _parseVersion.coerceVersion)(kubernetesVersion), 'v1.15.3');
    }),
    projectNetworkIsolationAvailable: Ember.computed('config.network.plugin', function () {
      var plugin = Ember.get(this, 'config.network.plugin');
      return plugin === CANAL;
    }),
    setFlannelLables: function setFlannelLables() {
      var flannel = this.networkContent.findBy('value', 'flannel');

      if (Ember.get(this, 'isCustom')) {
        Ember.set(flannel, 'label', 'clusterNew.rke.network.flannelCustom');
      } else {
        Ember.set(flannel, 'label', 'clusterNew.rke.network.flannel');
      }
    }
  });

  _exports.default = _default;
});