define("shared/components/catalog-index/component", ["exports", "shared/utils/constants", "shared/components/catalog-index/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    catalog: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    search: '',
    parentRoute: null,
    launchRoute: null,
    updating: 'no',
    istio: false,
    projectId: Ember.computed.alias("cookies.".concat(_constants.default.COOKIE.PROJECT)),
    catalogs: Ember.computed.union('model.catalogs.globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, 'catalog.componentRequestingRefresh');
    },
    actions: {
      clearSearch: function clearSearch() {
        Ember.set(this, 'search', '');
      },
      update: function update() {
        var _this = this;

        Ember.set(this, 'updating', 'yes');
        Ember.get(this, 'catalog').refresh().then(function () {
          Ember.set(_this, 'updating', 'no');

          if (_this.refresh) {
            _this.refresh();
          }
        }).catch(function () {
          Ember.set(_this, 'updating', 'error');
        });
      },
      filterCatalog: function filterCatalog(category, dropdown) {
        if (dropdown && dropdown.isOpen) {
          dropdown.actions.close();
        }

        this.categoryAction(category);
      },
      toggleCollapse: function toggleCollapse(group) {
        var collapsedNames = Ember.get(this, "prefs.".concat(_constants.default.PREFS.COLLAPSED_CATALOGS)) || [];

        if (group.collapsed) {
          collapsedNames.removeObject(group.name);
        } else {
          collapsedNames.addObject(group.name);
        }

        Ember.set(group, 'collapsed', !group.collapsed);
        Ember.set(this, "prefs.".concat(_constants.default.PREFS.COLLAPSED_CATALOGS), collapsedNames);
      }
    },
    childRequestiongRefresh: Ember.observer('catalog.componentRequestingRefresh', function () {
      if (Ember.get(this, 'catalog.componentRequestingRefresh')) {
        this.send('update');
      }
    }),
    projectCatalogs: Ember.computed('model.catalogs.projecCatalogs', function () {
      var _this2 = this;

      return (Ember.get(this, 'model.catalogs.projectCatalogs') || []).filter(function (c) {
        return c.projectId === Ember.get(_this2, 'scope.currentProject.id');
      });
    }),
    clusterCatalogs: Ember.computed('model.catalogs.clusterCatalogs', function () {
      var _this3 = this;

      return (Ember.get(this, 'model.catalogs.clusterCatalogs') || []).filter(function (c) {
        return c.clusterId === Ember.get(_this3, 'scope.currentCluster.id');
      });
    }),
    categories: Ember.computed('matchingSearch.@each.{category,categories}', function () {
      var map = {};
      Ember.get(this, 'matchingSearch').forEach(function (tpl) {
        var categories = tpl.categories;

        if (!categories) {
          return;
        }

        for (var i = 0; i < categories.length; i++) {
          var ctgy = categories[i];
          var normalized = ctgy.underscore();

          if (map[normalized]) {
            map[normalized].count += 1;
          } else {
            map[normalized] = {
              name: ctgy,
              category: normalized,
              count: 1
            };
          }
        }
      });
      return Object.values(map);
    }),
    catalogURL: Ember.computed('catalogs', function () {
      var neu = {
        catalogs: {}
      };
      Ember.get(this, 'catalogs').forEach(function (cat) {
        neu.catalogs[cat.id] = {
          branch: cat.branch,
          url: cat.url
        };
      });
      return JSON.stringify(neu);
    }),
    inScopeTemplates: Ember.computed('catalog._allTemplates.@each.{name,id,catalogId}', 'scope.currentProject', 'istio', function () {
      var svc = Ember.get(this, 'catalog');
      return svc.filter(Ember.get(svc, '_allTemplates'), Ember.get(this, 'scope.currentProject'), Ember.get(this, 'istio'));
    }),
    matchingSearch: Ember.computed('inScopeTemplates.@each.{name,description}', 'search', function () {
      var search = (Ember.get(this, 'search') || '').toLowerCase();
      var all = Ember.get(this, 'inScopeTemplates');

      if (!search) {
        return all;
      }

      return all.filter(function (tpl) {
        return tpl.name.toLowerCase().includes(search) || tpl.description.toLowerCase().includes(search);
      });
    }),
    arrangedContent: Ember.computed('matchingSearch.@each.categoryLowerArray', 'category', function () {
      var category = (Ember.get(this, 'category') || '').toLowerCase();
      var all = Ember.get(this, 'matchingSearch').filter(function (tpl) {
        return Object.keys(Ember.get(tpl, 'versionLinks') || {}).length > 0;
      });

      if (!category || category === 'all') {
        return all;
      }

      return all.filter(function (tpl) {
        return Ember.get(tpl, 'categoryLowerArray').includes(category);
      });
    }),
    groupedContent: Ember.computed('arrangedContent.[]', 'catalogs.@each.name', function () {
      var out = [];
      var collapsedNames = Ember.get(this, "prefs.".concat(_constants.default.PREFS.COLLAPSED_CATALOGS)) || [];
      var all = Ember.get(this, 'arrangedContent');
      all.forEach(function (template) {
        var entry = getOrCreateGroup(template.displayCatalogId);
        entry.items.push(template);
      });

      if (!Ember.isEmpty(out)) {
        out.forEach(function (c) {
          var isHelm3 = c.items.any(function (i) {
            var catalogRef = i.catalogRef,
                clusterCatalog = i.clusterCatalog,
                projectCatalog = i.projectCatalog;

            if (catalogRef && catalogRef.isHelm3 || clusterCatalog && clusterCatalog.isHelm3 || projectCatalog && projectCatalog.isHelm3) {
              return true;
            }
          });
          Ember.set(c, 'isHelm3', isHelm3);
        });
      }

      return out.sortBy('priority', 'name');

      function getOrCreateGroup(name) {
        var entry = out.findBy('name', name);
        var priority = 0;

        if (name === _constants.default.CATALOG.LIBRARY_KEY) {
          priority = 1;
        } else if ([_constants.default.CATALOG.HELM_STABLE_KEY, _constants.default.CATALOG.HELM_INCUBATOR_KEY, _constants.default.CATALOG.ALIBABA_APP_HUB_KEY].includes(name)) {
          priority = 2;
        }

        if (!entry) {
          entry = {
            name: name,
            priority: priority,
            collapsed: collapsedNames.includes(name),
            items: []
          };
          out.push(entry);
        }

        return entry;
      }
    })
  });

  _exports.default = _default;
});