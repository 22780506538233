define("global-admin/components/cru-cloud-credential/component", ["exports", "shared/mixins/view-new-edit", "global-admin/components/cru-cloud-credential/template", "shared/utils/amazon"], function (_exports, _viewNewEdit, _template, _amazon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CRED_CONFIG_CHOICES = [{
    name: 'amazon',
    displayName: 'Amazon',
    driver: 'amazonec2',
    configField: 'amazonec2credentialConfig'
  }, {
    name: 'azure',
    displayName: 'Azure',
    driver: 'azure',
    configField: 'azurecredentialConfig'
  }, {
    name: 'digitalOcean',
    displayName: 'Digital Ocean',
    driver: 'digitalocean',
    configField: 'digitaloceancredentialConfig'
  }, {
    name: 'oci',
    displayName: 'OCI',
    driver: 'oci',
    configField: 'ocicredentialConfig'
  }, {
    name: 'linode',
    displayName: 'Linode',
    driver: 'linode',
    configField: 'linodecredentialConfig'
  }, {
    name: 'vmware',
    displayName: 'VMware vSphere',
    driver: 'vmwarevsphere',
    configField: 'vmwarevspherecredentialConfig'
  }];

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    globalStore: Ember.inject.service(),
    digitalOcean: Ember.inject.service(),
    linode: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    nodeConfigTemplateType: null,
    cloudCredentialType: null,
    model: null,
    cancelAdd: null,
    doneSavingCloudCredential: null,
    disableHeader: false,
    validatingKeys: false,
    region: null,
    sinlgeCloudKeyChoice: null,
    regionChoices: _amazon.REGIONS,
    mode: 'new',
    init: function init() {
      this._super.apply(this, arguments);

      var cloudCredentialType = 'amazon';
      var model = null;

      if (Ember.get(this, 'driverName')) {
        var match = CRED_CONFIG_CHOICES.findBy('driver', Ember.get(this, 'driverName'));
        cloudCredentialType = Ember.get(match, 'name');
        model = this.globalStore.createRecord({
          type: 'cloudCredential'
        });
      } else {
        if (Ember.get(this, 'originalModel')) {
          var configField = Object.keys(this.originalModel).find(function (key) {
            return key.toLowerCase().includes('config');
          });
          var configChoice = CRED_CONFIG_CHOICES.findBy('configField', configField);
          cloudCredentialType = Ember.get(configChoice, 'name');
          model = this.originalModel.clone();
        } else {
          model = this.globalStore.createRecord({
            type: 'cloudCredential'
          });
        }
      }

      Ember.setProperties(this, {
        cloudCredentialType: cloudCredentialType,
        model: model
      });

      if (!Ember.get(this, 'originalModel')) {
        this.initCloudCredentialConfig();
      }
    },
    actions: {
      selectConfig: function selectConfig(configType) {
        this.cleanupPreviousConfig();
        Ember.set(this, 'cloudCredentialType', configType);
        this.initCloudCredentialConfig();
      }
    },
    config: Ember.computed('cloudCredentialType', 'model.{amazonec2credentialConfig,azurecredentialConfig,digitaloceancredentialConfig,linodecredentialConfig,ocicredentialConfig,vmwarevspherecredentialConfig}', function () {
      var model = this.model;
      var configField = this.getConfigField();
      return Ember.get(model, configField);
    }),
    configChoices: Ember.computed('driverName', function () {
      var _this = this;

      if (Ember.get(this, 'driverName')) {
        var driverName = this.driverName;
        var match = CRED_CONFIG_CHOICES.findBy('driver', driverName);
        Ember.run.next(function () {
          Ember.setProperties(_this, {
            cloudCredentialType: Ember.get(match, 'name'),
            singleCloudKeyChoice: Ember.get(match, 'displayName')
          });

          _this.initCloudCredentialConfig();
        });
        return [match];
      } else {
        return CRED_CONFIG_CHOICES.sortBy('displayName');
      }
    }),
    savingLabel: Ember.computed('validatingKeys', 'cloudCredentialType', function () {
      if (this.validatingKeys) {
        switch (this.cloudCredentialType) {
          case 'amazon':
          case 'digitalOcean':
          case 'linode':
            return 'modalAddCloudKey.saving.validating';

          case 'oci':
          case 'azure':
          case 'vmware':
          default:
            return 'saveCancel.saving';
        }
      }

      return 'saveCancel.saving';
    }),
    validate: function validate() {
      var ok = this._super.apply(this, arguments);

      var errors = [];
      var cloudCredentialType = this.cloudCredentialType;

      if (cloudCredentialType === 'amazon') {
        if (!Ember.get(this, 'region')) {
          ok = false;
          errors.pushObject(this.intl.t('modalAddCloudKey.errors.region'));
        }
      }

      this.parseAndCollectErrors(errors, true);
      return ok;
    },
    willSave: function willSave() {
      var _this2 = this;

      var ok = this._super.apply(this, arguments);

      if (!ok) {
        return ok;
      }

      var cloudCredentialType = this.cloudCredentialType;
      var keysThatWeCanValidate = ['amazon', 'digitalOcean', 'linode'];
      var auth = {
        type: 'validate',
        token: null
      };

      if (keysThatWeCanValidate.includes(cloudCredentialType)) {
        Ember.set(this, 'validatingKeys', true);

        if (cloudCredentialType === 'linode') {
          Ember.set(auth, 'token', Ember.get(this, 'config.token'));
          return this.linode.request(auth, 'profile').then(function () {
            Ember.set(_this2, 'validatingKeys', false);
            return true;
          }).catch(function (err) {
            return _this2.setError("".concat(err.status, " ").concat(err.statusText));
          });
        }

        if (cloudCredentialType === 'digitalOcean') {
          Ember.set(auth, 'token', Ember.get(this, 'config.accessToken'));
          return this.digitalOcean.request(auth, 'regions').then(function () {
            Ember.set(_this2, 'validatingKeys', false);
            return true;
          }).catch(function (err) {
            return _this2.setError("".concat(err.status, " ").concat(err.statusText));
          });
        }

        if (cloudCredentialType === 'amazon') {
          var authConfig = {
            accessKeyId: this.config.accessKey,
            secretAccessKey: this.config.secretKey,
            region: this.region
          };
          var ec2 = new AWS.EC2(authConfig);
          return new Ember.RSVP.Promise(function (resolve, reject) {
            ec2.describeAccountAttributes({}, function (err) {
              if (err) {
                reject(err);
              }

              return resolve();
            });
          }).then(function () {
            Ember.set(_this2, 'validatingKeys', false);
            return true;
          }).catch(function (err) {
            return _this2.setError("".concat(err.statusCode, " ").concat(err.code));
          });
        }
      }

      Ember.set(this, 'validatingKeys', false);
      return ok;
    },
    setError: function setError() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var translation = this.intl.t('modalAddCloudKey.errors.validation', {
        status: message
      });
      Ember.set(this, 'validatingKeys', false);
      this.parseAndCollectErrors(translation, true);
      return false;
    },
    initCloudCredentialConfig: function initCloudCredentialConfig() {
      var model = this.model;
      var configField = this.getConfigField();

      if (configField) {
        Ember.set(model, configField, this.globalStore.createRecord({
          type: configField.toLowerCase()
        }));
      }
    },
    doneSaving: function doneSaving(neu) {
      this.doneSavingCloudCredential(neu);
    },
    cleanupPreviousConfig: function cleanupPreviousConfig() {
      var model = this.model;
      var configField = this.getConfigField();

      if (configField) {
        delete model[configField];
      }
    },
    getConfigField: function getConfigField() {
      var cloudCredentialType = this.cloudCredentialType,
          configChoices = this.configChoices;

      if (cloudCredentialType) {
        var matchType = configChoices.findBy('name', cloudCredentialType);
        return Ember.get(matchType, 'configField');
      }

      return;
    },
    parseNodeTemplateConfigType: function parseNodeTemplateConfigType(nodeTemplate) {
      return Object.keys(nodeTemplate).find(function (f) {
        return f.toLowerCase().indexOf('config') > -1;
      });
    },
    parseAndCollectErrors: function parseAndCollectErrors() {
      throw new Error('parseAndCollectErrors action is required!');
    }
  });

  _exports.default = _default;
});