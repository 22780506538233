define("shared/components/storage-class/provisioner-longhorn/component", ["exports", "shared/components/storage-class/provisioner-longhorn/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_PROVISIONER_CONFIG = {
    numberOfReplicas: '3',
    staleReplicaTimeout: '2880',
    fromBackup: '',
    diskSelector: '',
    nodeSelector: '',
    recurringJobs: ''
  };

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'longhorn',
    mode: 'new',
    defaultProvisionerConfig: DEFAULT_PROVISIONER_CONFIG,
    fields: Object.keys(DEFAULT_PROVISIONER_CONFIG),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var fields = this.fields,
          defaultProvisionerConfig = this.defaultProvisionerConfig;

      if (fields.length > 0) {
        var changes = {};
        fields.forEach(function (field) {
          var key = field;
          changes[key] = Ember.get(_this, "parameters.".concat(key)) || Ember.get(defaultProvisionerConfig, key) || '';
        });
        Ember.setProperties(this, {
          model: changes
        });
      }
    }
  });

  _exports.default = _default;
});